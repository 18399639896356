.hero {
   margin-top: 5%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   @include respond(laptop){
      margin-top: 8%;
   }
   @include respond(tab-port){
      margin-top: 10%;
   }
   @include respond(phone-land){
      margin-top: 20%;
   }
   @include respond(phone-port){
      margin-top: 24%;
   }
   .container{
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond(tab-port){
         height: 70%;
      }
      @include respond(phone-land){
         height: 100%;
      }
      @include respond(phone-port){
         height: 100%;
      }
   }
   
   &__content {
      height: 80%;
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: auto 0;
      @include respond(tab-land) {
         width: 85%;
      }
      @include respond(tab-port){
         width: 85%;
      }
      @include respond(phone-land){
         width: 80%;
         height: 50%;
      }
      @include respond(phone-port) {
         height: 50%;
         width: 100%;
      }
      @include respond(iphone-5) {
         padding: 0 2%;
      }
   }

   h1 {
      font-size: 4.8rem;
      font-weight: 600;
      text-align: center;
      @include respond(laptop) {
         font-size: 4.2rem;
      }
      @include respond(tab-port) {
         font-size: 3.6rem;
      }
      @include respond(phone-land){
         font-size: 3rem;
      }
      @include respond(phone-port) {
         font-size: 3.4rem;
         width: 100%;
      }
      @include respond(iphone-12) {
         font-size: 3rem;
      }
      @include respond(iphone-5) {
         font-size: 3.4rem;
      }
   }

   h2 {
      font-size: 2.4rem;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      width: 100%;
      @include respond(laptop) {
         font-size: 2rem;
      }
      @include respond(macbook-air) {
         margin-top: 1.2rem;
         margin-bottom: 1.2rem;
      }
      @include respond(tab-land) {
         font-size: 2rem;
      }

      @include respond(tab-port) {
         font-size: 1.8rem;
      }
      @include respond(phone-port) {
         font-size: 2rem;
      }
      @include respond(iphone-12) {
         font-size: 1.8rem;
      }
      @include respond(iphone-5) {
         font-size: 1.8rem;
      }

   }

   &__intro {
      margin: 0 auto;
      font-size: 2rem;
      width: 90%;
      margin-bottom: 0;
      margin-top: 2%;
      @include respond(tab-land) {
         font-size: 2rem;
      }
      @include respond(phone-land) {
         font-size: 2.2rem;
      }

      @include respond(tab-port) {
         font-size: 2.2rem;
         margin-top: 5%;
         width: 100%;
      }
      @include respond(phone-port) {
         margin-top: 3%;
         width: 90%;
         font-size: 1.8rem;
      }

   }

   
   &__bottom{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2%;
      p{
         @include respond(tab-port) {
            font-size: 2.2rem;
         }
         @include respond(phone-land) {
            font-size: 2.2rem;
         }
         @include respond(phone-port) {
            font-weight: 300;
            font-size: 1.8rem;
         }
      }
      .btn {
         width: 45%;
         @include respond(tab-land) {
            width: 60%;
         }
         @include respond(tab-port) {
            width: 90%;
            font-size: 2rem;
         }
         @include respond(phone-land) {
            width: 80%;
            font-size: 2rem;
         }
         @include respond(phone-port) {
            font-weight: 400;
            padding-left: 3%;
            padding-right: 3%;
         }
         @include respond(iphone-5) {

            padding-left: 3%;
            padding-right: 3%;
         }
      }
   }

   &__video-container {
      width: 65%;

      @include respond(laptop) {
         width: 75%;
      }
      
      @include respond(tab-port) {
         width: 90%;
      }
      @include respond(phone-port) {
         width: 100%;
      }

   }

   &-video{
      display: block;
      width: 100%;
      margin: 2rem auto 0 auto;
      padding: 56.25% 0 0 0;
      position: relative;

      

      iframe {
         position:absolute;
         top:0;
         left:0;
         width:100%;
         height:100%;
      }
   }

}