.contact {
   margin-top: 12rem;
   background-color: var(--color-primary);
   border-radius: 20px;
   padding: 5rem 7rem;
   @include respond(tab-port) {
      margin-top: 8rem;
   }
   @include respond(phone-port) {
      padding: 3rem 4rem;
   }

   h3 {
      text-align: left;
      color: #fff;
      font-size: 2.8rem;

      @include respond(tab-land) {
         font-size: 2.4rem;
      }

      @include respond(phone-port) {
         font-size: 2rem;
      }
   }

   p {
      margin-bottom: 6rem;
      color: #fff;
      font-size: 2.4rem;

      @include respond(tab-land) {
         font-size: 2rem;
      }

      @include respond(phone-land) {
         font-size: 1.8rem;
      }

      @include respond(phone-port) {
         font-size: 1.4rem;
         margin-bottom: 3rem;
      }

   }

   &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include respond(phone-land) {
         margin-top: 4rem;
      }

      &-left {
         width: 40%;
         display: flex;
         flex-direction: column;
         justify-content: space-between;

         @include respond(tab-land) {
            width: 45%;
         }

         @include respond(phone-port) {
            width: 94%;
         }


         &_label {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            input{
               margin-right: 1rem;
               width: 1.8rem;
               height: 1.8rem;
            }
            a{
               color: #fff;
               font-size: 1.6rem;
               margin: 0 0.5rem;
               text-decoration: underline;

               @include respond(tab-land){
                  font-size: 1.2rem;
               }

               @include respond(tab-port){
                  font-size: 1.2rem;
               }
            }
         }

         span{
            display: flex;
            color: #fff;
            font-size: 1.6rem;

            @include respond(tab-land){
               font-size: 1.2rem;
            }

            @include respond(tab-port){
               font-size: 1.2rem;
            }
         }

         &__input {
            width: 100%;
            height: 6rem;
            padding-left: 1rem;
            margin-top: 1rem;
            &_name{
               margin-top: 0;
            }
            @include respond(phone-land) {
               height: 5rem;
            }
            @include respond(phone-port) {
               margin-top: 1rem;
            }
         }
         textarea {
            width: 100%;
            min-height: 14rem;
            resize: none;
            margin-top: 1rem;
            padding: 1rem 0 4rem 1rem;

            @include respond(phone-land) {
               min-height: 10rem;
            }
            @include respond(phone-port) {
               margin-top: 1rem;
            }
         }
      }

      &-right {
         width: 40%;
         display: flex;
         flex-direction: column;
         justify-content: start;

         @include respond(tab-land) {
            width: 45%;
         }

         @include respond(phone-port) {
            width: 94%;
         }


         button {
            margin-top: 5rem;
            width: 70%;

            @include respond(tab-land) {
               width: 100%;
            }
         }

         p{
            width: 100%;
            @include respond(phone-land) {
               width: 100%;
            }
            @include respond(phone-port) {
               margin-top: 3rem;
               margin-bottom: 0;
               width: 94%;
            }
         }

         a{
            margin-top: 2rem;
         }
      }
   }


   button {
      margin-top: 4.6rem;
      @include respond(phone-port) {
         margin-top: 3rem;
      }
   }
   
}

.intl-tel-input{
   margin-top: 1rem;
}