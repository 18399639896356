.logos {
   display: flex;
   align-items: center;
   height: 15vh;
   padding-top: 3%;
   &__container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      img{
         padding: 1%;
         width: 20%;
         @include respond(phone-port){
            width: 48%;
         }
      }
   }
}