.faq{
   margin-top: 12rem;
   padding: 0 6%;
   @include respond(phone-port){
      margin-top: 8rem;
   }
   &__title{
      width: 18%;
      border-bottom: 4px solid #000;
      margin: 0 auto;
      padding-bottom: 2rem;
      h2{
         text-align: center;
         margin: 0;
      }
   }
   &__content{
      margin-top: 6rem;
      display: flex;
      justify-content: space-between;
      @include respond(phone-port){
         flex-direction: column;
      }
   }
   &__column{
      width: 49%;
      @include respond(phone-port){
         width: 95%;
      }
      h3{
         text-align: left;
         font-weight: 600;
      }
      p{
         margin-bottom: 4rem;
         font-weight: 400;
         @include respond(phone-port) {
            font-size: 1.2rem;
          }
      }
   }
}