// IMPORT NORMALIZE.CSS
@import "../../node_modules/normalize.css/normalize.css";

@import "./abstracts/abstracts";
@import "./vendors/bootstrap-override";

// IMPORT BOOTSTRAP REQUIRED MODULES
@import "../node_modules/bootstrap/scss/bootstrap";

@import "./abstracts/mediaqueries";

@import "./base/base";

@import "./layout/header";
@import "./layout/navbar";

@import "./sections/hero";
@import "./sections/logos";
@import "./sections/tagline";
@import "./sections/testimonials";
@import "./sections/description";
@import "./sections/steps";
@import "./sections/benefits";
@import "./sections/functions";
@import "./sections/contact";
@import "./sections/faq";

@import "./layout/footer";

@import "./utils/utils";
