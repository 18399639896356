.footer{
   margin-top: 16rem;
   height: 120px;
   background-color: var(--color-primary);
   @include respond(tab-land) {
      margin-top: 12rem;
      height: 100px;
    }
    @include respond(phone-port) {
       margin-top: 8rem;
      height: 80px;
      padding-bottom: 4%;
    }
    @include respond(iphone-5) {
      margin-top: 5rem;
   }
   &__content{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond(phone-port) {
         flex-direction: column;
       }
      img{
         @include respond(phone-port) {
            width: 30%;
          }
      }
   }
   &__text{
      display: flex;
      @include respond(phone-port) {
         width: 70%;
         justify-content: space-between;
       }
      p{
         margin: auto 0;
         margin-left: 4rem;
         font-family: "Nunito", sans-serif;
         font-size: 1.4rem;
         font-weight: 200;
         color: #ffffff99;
         @include respond(tab-land) {
            font-size: 1rem;
            margin-left: 2rem;
          }
          @include respond(phone-port) {
            font-size: 0.8rem;
            margin: 0;
          }
      }
   }
}