.description {
   margin-top: 12rem;
   padding: 0 6%;
   @include respond(tab-port) {
      margin-top: 8rem;
    }
   h2 {
      color: var(--color-primary);
      text-transform: none;
      margin-bottom: 6rem;
      @include respond(iphone-5) {
         margin-bottom: 3rem;
      }
   }
   h3{
      width: 80%;
      margin: 0 auto;
      margin-bottom: 4rem;
   }
   p{
      margin-top: 3rem;
   }
   &__list-name{
      margin: 0;
      margin-top: 3rem;
   }
   &__img{
      margin-top: 10rem;
      width: 100%;
      @include respond(tab-port) {
         margin-top: 6rem;
      }
      @include respond(iphone-5) {
         margin-top: 3rem;
      }
   }
   &__mission{
      margin-top: 6rem;
      @include respond(iphone-5) {
         margin-top: 3rem;
      }
      p{
         margin: 2.4rem 0;
      }
   }
}