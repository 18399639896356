.testimonials {
   margin-top: 12rem;
   @include respond(tab-port) {
      margin-top: 8rem;
   }
   h2{
      color: var(--color-primary);
      text-transform: none;
      margin-bottom: 6rem;
      @include respond(phone-port) {
         margin-bottom: 0;
      }
   }
   &__container{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
   }
   &__case-study-card{
      width: 32%;
      display: flex;
      flex-direction: column;
      @include respond(phone-port) {
         width: 90%;
         margin: 0 auto;
         text-align: center;
         margin-top: 2rem;
         border-bottom: 1px solid #c6c6c6;
      }
      &:last-of-type{
         border-bottom: none;
      }
      p{
         margin-top: 3rem;
         font-size: 1.8rem;
         @include respond(tab-land) {
            font-size: 1.6rem;
          }
          @include respond(phone-port) {
            margin-top: 1rem;
         }
         @include respond(iphone-5) {
            font-size: 1.4rem;
         }
      }
      span{
         margin-top: 2rem;
         font-size: 1.6rem;
         font-style: italic;
         @include respond(phone-port) {
            padding-bottom: 2rem;
            font-size: 1.2rem;
         }
         
      }
   }
}