.functions{
   padding: 0 6%;
   margin-top: 12rem;
   @include respond(tab-port) {
      margin-top: 8rem;
    }
   h2{
      color: var(--color-primary);
      text-transform: none;
      text-align: center;
   }
   &__row{
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
      margin-bottom: 5rem;
      flex-wrap: wrap;
      @include respond(phone-port) {
         margin: 0;
      }
   }
   &__function{
      width: 49%;
      @include respond(phone-port) {
         width: 100%;
         margin: 0 auto;
         margin-top: 1.5rem;
         padding-bottom: 1.5rem;
         border-bottom: 1px solid #c6c6c6;
      }
      &_last{
         border-bottom: none;
      }
      h3{
         min-height: 6rem;
         @include respond(phone-port) {
            margin-bottom: 2rem;
            min-height: auto;
         }
      }
      img{
         width: 100%;
      }
      p{
         margin-top: 3rem;
      }
   } 
   &__btn-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      a{
         font-size: 2.4rem;
         @include respond(tab-land) {
            font-size: 2rem;
          }
         @include respond(phone-port) {
            font-size: 1.4rem;
         }
      }
   }
}