.tagline {
  margin-top: 10rem;
  @include respond(tab-port) {
   margin-top: 8rem;
 }
  h2 {
    color: var(--color-primary);
    text-transform: none;
    width: 70%;
    margin: 0 auto;
    line-height: 1.4;
    @include respond(phone-port) {
      width: 100%;
      font-size: 2rem;
    }
  }
}
