.benefits{
   padding: 0 6%;
   .benefit{
      h3{
         margin-top: 12rem;
         @include respond(tab-port) {
            margin-top: 6rem;
         }
      }
      h2{
         color: var(--color-primary);
         text-transform: none;
         margin-bottom: 5rem;
      }
      p{
         margin-top: 3rem;
      }
   }
}