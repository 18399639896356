.steps{
   padding: 0 6%;
   h3{
      text-transform: uppercase;
   }
   h2{
      color: var(--color-primary);
      text-transform: none;
   }

   &__img-container{
      margin: 0 auto;
      width: 80%;
      img{
         width: 100%;
      }
   }

   &__row{
      margin-top: 4rem;
      padding-bottom: 4rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #c6c6c6;
      h3{
         text-align: left;
      }
      &_last{
         border: none;
         padding: 0;
      }
   }
   &__item{
      width: 48%;
   }
   &__step{
      margin-top: 12rem;
      @include respond(tab-port) {
         margin-top: 8rem;
      }
      p{
         margin-top: 4rem;
         
      }
      
   }
}